<template>
  <div class="pay-success">
    <div class="pay-top" :class="pay_status?'':'payFailure'"></div>
    <div class="pay-con">
      <div class="wx-pay-icon">
        <img src="@static/vip/balancesuccess.png" v-if=" type == 1" alt=""/>
        <img src="@static/home/wx_pay.png" v-else-if="isWeixin_status && pay_status" alt=""/>
        <img src="@static/home/alipay.png" v-else-if="!isWeixin_status && pay_status" alt=""/>
        <img src="@static/vip/payshibai.png" v-else alt=""/>
        <span class="wx-pay-text" :style="{color:pay_status?'':'red'}">{{pay_status?'支付成功':"支付失败"}}</span>
      </div>
      <div class="wx-pay-con">
        <div class="shop-name">{{detObj.room.name}}</div>
        <div class="wx-pay-price">
          <span>¥</span>
          <span>{{detObj.amount}}</span>
        </div>
      </div>
      <CellGroup>
        <Cell title="订单号" :value="order_num"></Cell>
        <Cell title="名称" :value="detObj.name"></Cell>
        <Cell title="应付金额" :value="detObj.money"></Cell>
        <Cell title="实付金额" :value="detObj.amount"></Cell>
        <Cell title="使用积分" :value="detObj.point"></Cell>
        <Cell title="充值优惠" :value="detObj.pay_discount"></Cell>
        <Cell title="积分抵扣金额" :value="detObj.dec_point_money"></Cell>
        <Cell title="折扣抵扣金额" :value="detObj.dec_discount_money"></Cell>
        <Cell title="获得积分" :value="detObj.take_point"></Cell>
        <Cell title="创建时间" :value="detObj.create_time"></Cell>
      </CellGroup>
    </div>
    <Button type="primary" @click="goVip" block style="margin-top:110px" v-if="pay_status">
      {{ btn_status ? "去抽奖": "完成" }}
    </Button>
    <Button type="danger" @click="goCenter" block style="margin-top:110px" v-else>取消</Button>
  </div>
</template>
<script>
import { CellGroup, Cell, Button,Toast } from "vant"
import CreditShop from "@/api/creditshop";
import Setting from "@/api/setting";
export default {
  components: {
    CellGroup, Cell, Button
  },
  data() {
    return {
      showPage: false,
      order_num: "",
      detObj: {
        room: {},
        card: {}
      },
      type: 0,
      status: null,
      pay_status: true,
      btn_status: Boolean
    }
  },
  created() {
    Toast.loading({
      message: '获取订单信息...',
      forbidClick: true,
      duration: 0,
      overlay: true
    });
    this.order_num = this.$route.query.ordersn;
    this.type = this.$route.query.type;
    this.getDet(this.order_num);
  },
  methods: {
    // 判断是否有大转盘
    async getConfig() {
      try {
        const res = await Setting.roundConfig(this.detObj.amount, this.detObj.goods_id);
        if (res.code == 200) {
          if (res.data.length == 0) {
            this.btn_status = false;
          } else {
            this.btn_status = true;
          }
        } else {
          this.btn_status = false;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getDet() {
      try {
        const res = await CreditShop.oilOrderDetail(this.order_num);
        if (res.code == 200) {
          this.detObj = res.data;
          this.getConfig();
          if (this.$route.query.hasOwnProperty("status")) {
            if (this.$route.query.status == "TRADE_SUCCESS") {
              this.status = true
              this.pay_status = true
            } else {
              this.status = false
              this.pay_status = false
              document.title = "支付失败"
            }
          } else {
            if (this.detObj.pay_status == 1) {
              this.pay_status = false
              document.title = "支付失败"
            } else {
              this.pay_status = true
            }
          }
          Toast.clear();
        }
      } catch (error) {
        console.log(error);
      }
    },
    goVip() {
      if (this.btn_status) {
        this.$router.push('/turntable?entry_type=2')
      } else {
        this.$router.push('/vip')
      }
    },
    goCenter() {
      this.$router.push('/vip')
    }
  }
}
</script>
<style lang="scss" scoped>
$bgcolor:rgba(244,244,244,1);
.nonePage {
  display: none;
}
.pay-success {
  width: 100%;
  height: auto;
  background-color: $bgcolor;
  .pay-top {
    width: 100%;
    height: 140px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #1AAD19;
  }
  .payFailure {
    background-color: red;
  }
  .pay-con {
    width: 96%;
    height: 90%;
    margin: -90px auto;
    z-index: 1000;
    padding-top: 30px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #ffffff;
    .wx-pay-icon {
      width: 80px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      .wx-pay-text {
        color: #1AAD19;
        font-size: 18px;
        letter-spacing: 2px;
      }
    }
    .wx-pay-con {
      width: 100%;
      height: 100px;
      display: flex;
      padding: 16px 10px;
      margin-top: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;
      .wx-pay-price {
        span:nth-child(1) {
          font-size: 16px;
        }
        span:nth-child(2) {
          font-weight: 500;
          font-size: 26px;
        }
      }
      .shop-name {
        color: #696969;
        font-size: 14px;
        letter-spacing: 2px;
        padding-left: 12px;
      }
    }
  }
 }
</style>